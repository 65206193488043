import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const RemoveTenantButton = () => {
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleClose = () => {
    setShow(false);
    setPhoneNumber("");
    setError("");
    setSuccess("");
  };

  const handleShow = () => setShow(true);

  const handleRemove = async () => {
    if (!phoneNumber) {
      setError("Phone number is required");
      return;
    }

    try {
      const response = await axios.delete(
        `https://noidhousingbackend.mathenoid.com/api/tenants/remove`,
        { data: { phone: phoneNumber } }
      );
      if (response.data.success) {
        await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/updateVacantRooms"
        );
        setSuccess(response.data.message);
        setError("");
      } else {
        setError(response.data.errors);
        setSuccess("");
      }
    } catch (error) {
      setError("Error removing tenant");
      setSuccess("");
    }
  };

  return (
    <>
      <Button
        className="btn-danger text-nowrap btn btn-sm"
        onClick={handleShow}
      >
        Remove Tenant
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Remove Tenant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <div className="alert alert-danger">{error}</div>}
          {success && <div className="alert alert-success">{success}</div>}
          <Form>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tenant's phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>
            <Button variant="danger" onClick={handleRemove}>
              Confirm Remove
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveTenantButton;

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./LoginForm.css";
import Logo from "../Logo/Logo";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import InputGroup from "react-bootstrap/InputGroup";
import UserContext from "../../contexts/UserContext";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
  });
  const { setUser, setPhoneNumber } = useContext(UserContext);
  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const login = async () => {
    console.log("Login function Executed", formData);

    let responseData;
    await fetch("https://noidhousingbackend.mathenoid.com/loginLandlord", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => (responseData = data));

    if (responseData.success) {
      localStorage.setItem("auth-token", responseData.token);
      setUser(formData.username);
      setPhoneNumber(formData.password);

      // Call the updateVacantRooms API
      await fetch(
        "https://noidhousingbackend.mathenoid.com/api/updateVacantRooms",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            console.log("Vacant rooms updated successfully");
          } else {
            console.error("Error updating vacant rooms:", data.errors);
          }
        });

      // Call the calculate-arrears-all API
      await fetch(
        "https://noidhousingbackend.mathenoid.com/api/calculate-arrears-all",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            console.log(
              "Arrears calculated and inserted successfully for all tenants"
            );
          } else {
            console.error("Error calculating arrears:", data.errors);
          }
        });

      navigate("/Dashboard");
    } else {
      alert(responseData.errors);
    }
  };

  return (
    <>
      <Logo />
      <div className="title mb-4">
        <h3 className="text-center">Mathenoid housing system</h3>
      </div>
      <div className="shadowdiv">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              name="username"
              value={formData.username}
              onChange={changeHandler}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={changeHandler}
              />
              <InputGroup.Text
                onClick={toggleShowPassword}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <EyeSlash /> : <Eye />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div className="mt-3">
            <Button className="btn btn-sm" variant="primary" onClick={login}>
              Login
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default LoginForm;

import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import moment from "moment-timezone";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Fetch notifications from the backend
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/notifications/landlord"
        );
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  // Function to group notifications by date
  const groupNotificationsByDate = () => {
    const groupedNotifications = notifications.reduce((acc, notification) => {
      const dateKey = moment
        .tz(notification.date, "Africa/Nairobi")
        .format("YYYY-MM-DD"); // Group by date in Nairobi time zone
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(notification);
      return acc;
    }, {});

    return groupedNotifications;
  };

  const groupedNotifications = groupNotificationsByDate();

  return (
    <div style={{ margin: "20px" }}>
      <h2 style={{ fontWeight: "bold", marginBottom: "15px" }}>
        Notifications
      </h2>
      {Object.keys(groupedNotifications).map((dateKey) => (
        <div key={dateKey} style={{ marginBottom: "20px" }}>
          <h4 style={{ fontWeight: "400", marginBottom: "10px" }}>
            {moment(dateKey).format("dddd, MMMM Do YYYY")}
          </h4>
          <Table striped hover>
            <thead>
              <tr>
                <th
                  style={{
                    fontWeight: "bolder",
                    fontSize: "larger",
                    color: "#69c37b",
                  }}
                >
                  Serial No.
                </th>
                <th
                  style={{
                    fontWeight: "bolder",
                    fontSize: "larger",
                    color: "#69c37b",
                  }}
                >
                  From
                </th>
                <th
                  style={{
                    fontWeight: "bolder",
                    fontSize: "larger",
                    color: "#69c37b",
                  }}
                >
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              {groupedNotifications[dateKey].map((notification, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{notification.source}</td>
                  <td>{notification.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default Notifications;

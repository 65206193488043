import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CaretakerInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Dp from "../../Assets/Images/person.png";

const CaretakerInitial = () => {
  const [profileImage, setProfileImage] = useState(Dp);
  const [caretakerData, setCaretakerData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    idNo: "",
    phoneNumber: "",
    whatsappNumber: "",
    age: "",
    gender: "",
  });

  useEffect(() => {
    const fetchCaretakerData = async () => {
      try {
        const response = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/caretaker/details"
        );
        if (response.data.success) {
          setCaretakerData(response.data.caretaker);
          setFormData(response.data.caretaker);
        } else {
          console.error("Error fetching caretaker data:", response.data.errors);
        }
      } catch (error) {
        console.error("Error fetching caretaker data:", error);
      }
    };

    fetchCaretakerData();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = { ...prevData, [name]: value };
      console.log("Updated Form Data:", newData);
      return newData;
    });
  };

  const handleUpdate = () => {
    setIsConfirming(true);
    setIsEditing(true); // Make inputs writable
  };

  const handleConfirmUpdate = async () => {
    try {
      console.log("Form Data:", formData);
      const response = await axios.put(
        "https://noidhousingbackend.mathenoid.com/api/caretaker/update",
        formData
      );
      if (response.data.success) {
        alert("Caretaker details updated successfully");
        setIsEditing(false);
        setIsConfirming(false);
      } else {
        alert("Error updating caretaker data: " + response.data.errors);
        setIsEditing(false);
        setIsConfirming(false);
      }
    } catch (error) {
      alert("Error updating caretaker data: " + error.message);
      setIsEditing(false);
      setIsConfirming(false);
    }
  };

  if (!caretakerData) {
    return <div>Loading...</div>;
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="mainProfileDiv">
        <div className="verticalLine"></div>
        <div className="secondDiv">
          <form>
            <div className="form-group">
              <label htmlFor="idNo">Id Number</label>
              <input
                type="text"
                className="form-control"
                id="idNo"
                name="idNo"
                value={formData.idNo}
                readOnly={!isEditing}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                readOnly={!isEditing}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="age">Age</label>
              <input
                type="text"
                className="form-control"
                id="age"
                name="age"
                value={formData.age}
                readOnly={!isEditing}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </div>

        <div className="thirdDiv">
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                readOnly={!isEditing}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="whatsappNumber">Whatsapp Number</label>
              <input
                type="text"
                className="form-control"
                id="whatsappNumber"
                name="whatsappNumber"
                value={formData.whatsappNumber}
                readOnly={!isEditing}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <input
                type="text"
                className="form-control"
                id="gender"
                name="gender"
                value={formData.gender}
                readOnly={!isEditing}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="updateButtonContainer">
        {isConfirming ? (
          <button className="btn btn-success" onClick={handleConfirmUpdate}>
            Confirm Caretaker Update
          </button>
        ) : (
          <button className="btn btn-success" onClick={handleUpdate}>
            Update
          </button>
        )}
      </div>
    </Box>
  );
};

export default CaretakerInitial;

import React, { useState, useEffect, useContext } from "react";
import { Container, Button, Form, Table, Alert } from "react-bootstrap";
import axios from "axios";
import "./NotifyComponent.css";
import UserContext from "../../contexts/UserContext";

const NotifyPage = () => {
  const { user } = useContext(UserContext);
  const [type, setType] = useState("caretaker");
  const [message, setMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");

  useEffect(() => {
    if (type === "tenants") {
      axios
        .get("https://noidhousingbackend.mathenoid.com/tenants")
        .then((response) => setTenants(response.data))
        .catch((error) => console.error("Error fetching tenants:", error));
    }
  }, [type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      message.trim() !== "" &&
      (type === "caretaker" || selectedRoom !== "")
    ) {
      setShowConfirmation(true);
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const handleConfirm = async () => {
    let phoneNumber = "";
    let destination = "";

    if (type === "caretaker") {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/api/caretaker/phone"
        );
        if (response.data.success) {
          phoneNumber = response.data.phoneNumber;
          destination = "Caretaker";
        } else {
          console.error("Caretaker not found");
          return;
        }
      } catch (error) {
        console.error("Error fetching caretaker's phone number:", error);
        return;
      }
    } else {
      try {
        const response = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/tenant/phone",
          { room: selectedRoom }
        );
        if (response.data.success) {
          phoneNumber = response.data.phoneNumber;
          destination = `Room ${selectedRoom}`;
        } else {
          console.error("Tenant not found");
          return;
        }
      } catch (error) {
        console.error("Error fetching tenant's phone number:", error);
        return;
      }
    }

    try {
      const notificationData = {
        message: message,
        destination: destination,
        useName: user,
        forWho: "landlord",
      };
      await axios.post(
        "https://noidhousingbackend.mathenoid.com/api/notify",
        notificationData
      );

      const messageDate = {
        message: message,
        mobile: phoneNumber,
      };
      await axios.post(
        "https://noidhousingbackend.mathenoid.com/sendsms",
        messageDate
      );

      const newNotification = {
        id: notifications.length + 1,
        type: destination,
        message: message,
      };

      setNotifications([...notifications, newNotification]);
      setMessage("");
      setSelectedRoom("");
      setShowConfirmation(false);
    } catch (error) {
      console.error("Error sending notification or SMS:", error);
    }
  };

  const handleCancel = () => {
    setMessage("");
    setSelectedRoom("");
    setShowConfirmation(false);
    setFormValid(false);
  };

  return (
    <Container className="notify-page mt-4" style={{ maxWidth: "95%" }}>
      <h2 className="text-start mb-4">Make Notifications</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>For Who:</Form.Label>
          <Form.Control
            as="select"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="caretaker">Caretaker</option>
            <option value="tenants">Tenants</option>
          </Form.Control>
        </Form.Group>

        {type === "tenants" && (
          <Form.Group>
            <Form.Label>Select Room:</Form.Label>
            <Form.Control
              as="select"
              value={selectedRoom}
              onChange={(e) => setSelectedRoom(e.target.value)}
              required
            >
              <option value="">Select Room</option>
              {tenants.map((tenant) => (
                <option key={tenant.id} value={tenant.room}>
                  {tenant.room}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label>Message:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            isInvalid={!formValid && message.trim() === ""}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter a message.
          </Form.Control.Feedback>
        </Form.Group>

        <div className="text-start my-2">
          <Button variant="primary" type="submit">
            Send
          </Button>
        </div>
      </Form>

      {showConfirmation && (
        <div className="confirmation-modal mt-4">
          <Alert variant="info">
            <p className="text-center">
              Are you sure you want to send this message to{" "}
              {type === "tenants" ? `Room ${selectedRoom}` : type}?
            </p>
            <div className="text-center">
              <Button variant="success" onClick={handleConfirm}>
                Confirm
              </Button>{" "}
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Alert>
        </div>
      )}

      {notifications.length > 0 && (
        <div className="notifications mt-5">
          <h3 className="text-start mb-3">Notifications Sent:</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial</th>
                <th>For</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification) => (
                <tr key={notification.id}>
                  <td>{notification.id}</td>
                  <td>{notification.type}</td>
                  <td>{notification.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default NotifyPage;

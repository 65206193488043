import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AddTenantButton.css";

const AddTenantButton = () => {
  const [show, setShow] = useState(false);
  const [tenantData, setTenantData] = useState({
    name: "",
    room: "",
    phoneNumber: "",
    whatsappNumber: "",
    idNo: "",
    date: new Date().toISOString().split("T")[0], // Set the default date to the current date
    gender: "",
    religion: "",
  });
  const [vacantRooms, setVacantRooms] = useState([]);

  useEffect(() => {
    const fetchVacantRooms = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/vacantrooms"
        );
        setVacantRooms(response.data);
      } catch (error) {
        console.error("Error fetching vacant rooms:", error);
      }
    };

    fetchVacantRooms();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTenantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Check if all required fields are filled
    const { name, room, phoneNumber, idNo } = tenantData;
    if (!name || !room || !phoneNumber || !idNo) {
      alert("Please fill in all required fields.");
      return;
    }

    if (window.confirm("Are you sure you want to add this tenant?")) {
      try {
        await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/tenants/add",
          tenantData
        );
        await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/updateVacantRooms"
        );
        alert("Tenant added successfully!");
        handleClose();
      } catch (error) {
        console.error("Error adding tenant:", error);
        alert("Failed to add tenant.");
      }
    }
  };

  return (
    <>
      <div className="w-100 d-flex justify-content-end">
        <Button className="btn btn-sm btn-success" onClick={handleShow}>
          Add Tenant
        </Button>
      </div>

      <Modal
        className="modalAddTenants mt-4"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Tenant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={tenantData.name}
                onChange={handleChange}
                placeholder="Enter tenant name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formRoom">
              <Form.Label>Room</Form.Label>
              <Form.Control
                as="select"
                name="room"
                value={tenantData.room}
                onChange={handleChange}
                required
              >
                <option value="">Select a room</option>
                {vacantRooms.map((room) => (
                  <option key={room.room} value={room.room}>
                    {room.room}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={tenantData.phoneNumber}
                onChange={handleChange}
                placeholder="Enter phone number"
                required
              />
            </Form.Group>

            <Form.Group controlId="formWhatsappNumber">
              <Form.Label>Whatsapp Number</Form.Label>
              <Form.Control
                type="text"
                name="whatsappNumber"
                value={tenantData.whatsappNumber}
                onChange={handleChange}
                placeholder="Enter whatsapp number"
              />
            </Form.Group>

            <Form.Group controlId="formIdNo">
              <Form.Label>ID Number</Form.Label>
              <Form.Control
                type="text"
                name="idNo"
                value={tenantData.idNo}
                onChange={handleChange}
                placeholder="Enter ID number"
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add Tenant
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTenantButton;

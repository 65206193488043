import React, { useState, useEffect } from "react";
import { Table, Container } from "react-bootstrap";
import axios from "axios";
import "./MovingOut.css";

const MovingOutTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/movingout"
        );
        setData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container className="mt-5">
      <h2 className="text-start mb-4">Moving out Applications</h2>
      <Table striped hover>
        <thead className="custom-thead">
          <tr>
            <th>Serial Number</th>
            <th>Name</th>
            <th>Room</th>
            <th>Type</th>
            <th>Month</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td> {/* Assuming serial number is index + 1 */}
              <td>{item.name}</td>
              <td>{item.room}</td>
              <td>{item.type}</td>
              <td>{item.month}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default MovingOutTable;

import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";

const Repository = () => {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ date: "", item: null });
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, item: file });
  };

  const handleAddItem = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("date", formData.date);
    formDataToSend.append("item", formData.item);

    try {
      const response = await axios.post(
        "https://noidhousingbackend.mathenoid.com/api/repository/upload",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        // Fetch the updated list of items after adding a new item
        const updatedResponse = await axios.get(
          "https://noidhousingbackend.mathenoid.com/api/repository"
        );
        setItems(updatedResponse.data);
        setFormData({ date: "", item: null });
        handleCloseModal();
      } else {
        console.error("Error uploading file:", response.data.errors);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleShare = () => {
    setShowSnackbar(true);
    setTimeout(() => setShowSnackbar(false), 5000);
  };

  const isFormValid = formData.date && formData.item;

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/api/repository"
        );
        setItems(response.data);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await axios.get(fileUrl, { responseType: "blob" });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="container mt-4">
      {items.length > 0 ? (
        <Table striped hover>
          <thead>
            <tr>
              <th>Serial</th>
              <th>Date</th>
              <th>Item</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{new Date(item.date).toLocaleDateString()}</td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        Download {item.file_url.split("/").pop()}
                      </Tooltip>
                    }
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownload(
                          `https://noidhousingbackend.mathenoid.com${item.file_url}`,
                          item.file_url.split("/").pop()
                        );
                      }}
                      className="text-decoration-none"
                    >
                      {item.file_url.split("/").pop()}
                    </a>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-5 text-warning">
          <h4>No records yet!</h4>
        </div>
      )}

      <Button
        variant="success"
        className="position-fixed"
        style={{ right: "20px", bottom: "20px" }}
        onClick={handleShowModal}
      >
        Add Item
      </Button>

      <Modal className="mt-5" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formItem">
              <Form.Label>Item</Form.Label>
              <Form.Control
                type="file"
                name="item"
                onChange={handleFileChange}
              />
            </Form.Group>
            <Button
              variant="primary"
              disabled={!isFormValid}
              onClick={handleAddItem}
            >
              Add Item
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Repository;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PaymentsTable.css";

const PaymentsTable = () => {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/api/paymentsperroom"
        );
        if (response.data.success) {
          setPayments(response.data.payments);
        } else {
          console.error(response.data.errors);
        }
      } catch (error) {
        console.error("Error fetching payments:", error);
      }
    };

    fetchPayments();
  }, []);

  const groupedData = payments.reduce((acc, entry) => {
    const date = new Date(entry.date).toLocaleDateString(); // Convert date to string format without time
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(entry);
    return acc;
  }, {});

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="payments-container">
      <div className="printPayments w-100 text-end p-4">
        <button className="btn btn-md btn-success" onClick={handlePrint}>
          print payments
        </button>
      </div>
      <h2 className="payments-title">Payments</h2>
      <div className="payments-content">
        {Object.entries(groupedData).map(([date, entries]) => (
          <div key={date} className="payment-group">
            <h3>{date}</h3>
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Receipt</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Amount (Ksh)</th>
                  <th scope="col">Room</th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, index) => (
                  <tr key={index}>
                    <td>{new Date(entry.date).toLocaleDateString()}</td>
                    <td>{entry.receipt}</td>
                    <td>{entry.payment}</td>
                    <td>Ksh {entry.amount}</td>
                    <td>{entry.room}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentsTable;

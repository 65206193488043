import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import UserContext from "../../contexts/UserContext";
import "./ProfileInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Dp from "../../Assets/Images/person.png";
import { Link } from "react-router-dom";

const ProfileInitial = () => {
  const [profileImage, setProfileImage] = useState(Dp);
  const { user } = useContext(UserContext);
  const [landlordData, setLandlordData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    user: user,
    name: "",
    idNo: "",
    phoneNumber: "",
    whatsappNumber: "",
  });

  useEffect(() => {
    const fetchLandlordData = async () => {
      try {
        const response = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/landlord/details",
          {
            name: user,
          }
        );
        if (response.data.success) {
          setLandlordData(response.data.tenant);
          setFormData(response.data.tenant);
        } else {
          console.error("Error fetching landlord data:", response.data.errors);
        }
      } catch (error) {
        console.error("Error fetching landlord data:", error);
      }
    };

    fetchLandlordData();
  }, [user]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = { ...prevData, [name]: value };
      console.log("Updated Form Data:", newData);
      return newData;
    });
  };

  const handleUpdate = async () => {
    try {
      console.log("Form Data:", formData);
      const response = await axios.put(
        "https://noidhousingbackend.mathenoid.com/api/landlord/update",
        formData
      );
      if (response.data.success) {
        alert("Landlord details updated successfully");
        setIsEditing(false);
      } else {
        console.error("Error updating landlord data:", response.data.errors);
      }
    } catch (error) {
      console.error("Error updating landlord data:", error);
    }
  };

  if (!landlordData) {
    return <div>Loading...</div>;
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="mainProfileDiv">
        <div className="firstDiv">
          <div className="dp">
            <img
              src={profileImage}
              alt="profile"
              width="80px"
              height="80px"
              style={{ borderRadius: "40px" }}
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="upload-profile-image"
            />
            <label htmlFor="upload-profile-image" className="upload-icon">
              &#x1F4C2; {/* This is a folder icon for upload */}
            </label>
            <label>{user}</label>
          </div>
          <div className="menus">
            <button onClick={() => setIsEditing(false)} className="navBtns">
              Personal Details
            </button>

            <Link className="linkNoDecoration navBtns" to="/Repository">
              <button className="navBtns">Repository</button>
            </Link>

            <button
              className="navBtns"
              onClick={() => setIsEditing(!isEditing)}
            >
              Update Profile
            </button>
          </div>
        </div>
        <div className="verticalLine"></div>
        <div className="secondDiv">
          <form>
            <div className="form-group">
              <label htmlFor="idNo">Id Number</label>
              <input
                type="text"
                className="form-control"
                id="idNo"
                name="idNo"
                value={formData.idNo}
                readOnly
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                readOnly={!isEditing}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </div>

        <div className="thirdDiv">
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                readOnly
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="whatsappNumber">Whatsapp Number</label>
              <input
                type="text"
                className="form-control"
                id="whatsappNumber"
                name="whatsappNumber"
                value={formData.whatsappNumber}
                readOnly={!isEditing}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </div>
      </div>{" "}
      {isEditing && (
        <div className="updateButtonContainer">
          <button className="btn btn-success" onClick={handleUpdate}>
            Update
          </button>
        </div>
      )}
    </Box>
  );
};

export default ProfileInitial;
